import ReportDataService from "@/views/reports/services/ReportDataService";
import axios from "@/libs/axios";

export default class LiveShareErrorDataService extends ReportDataService{
    apiUrl = 'reports/live-share';

    updateErrorStatus(data) {
        return axios.put(`${this.apiUrl}/update-status`, {data});
    }
}
