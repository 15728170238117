import ReportDataService from "./ReportDataService";
import getBrand from "@/config/branding-config";
import router from "@/router";

export default class TradingReportDataService extends ReportDataService {
    apiUrl = 'reports/trading-overview';

    defaultInterval = "D";

    chartInterval = this.defaultInterval;

    allowedIntervals = [this.defaultInterval];

    countYearsChart;

    countMonthsChart;

    countWeeksChart;

    countDaysChart;

    charts = [
        {
            isActive: true,
            name: 'stock&sales',
            type: 'multiLineChart',
            option: {
                series: [],
                subCharts: [
                    {
                        seriesKey: 'sales',
                        format: 'price',
                        color: getBrand().primaryColor,
                    },
                    {
                        seriesKey: 'stock',
                        format: 'number',
                        color: getBrand().secondaryColor,
                    }
                ],
                allowedIntervals: this.allowedIntervals,
                chartInterval: this.chartInterval,
            },
            defaultOption: {
                series: [],
                allowedIntervals: [],
                chartInterval: '',
                subCharts: [],
                xAxisData: ""
            }
        },
    ]

    update(param) {
        this.chartInterval = param.chartInterval
    }

    handleChartResponse(res) {
        const response = res.data.data
        this.charts[0].option.chartInterval = this.chartInterval
        this.charts[0].option.allowedIntervals = response.allowedIntervals
        this.charts[0].option.xAxisData = response.xAxisData
        this.charts[0].option.series = []
        this.charts[0].option.subCharts.forEach(item => {
            this.charts[0].option.series.push(response[item.seriesKey])
        })
        this.charts[0].isActive = true
    }

    // eslint-disable-next-line class-methods-use-this
    getSku() {
        return router.currentRoute.params.sku
    }

    // eslint-disable-next-line class-methods-use-this
    getChartId() {
        return this.getSku()
    }

    getChartParams() {
        const params = {
            chartInterval: this.chartInterval,
        }
        switch (this.chartInterval) {
            case 'Y':
                params.countYearsChart = this.countYearsChart;
                break
            case 'M':
                params.countMonthsChart = this.countMonthsChart;
                break
            case 'W':
                params.countWeeksChart = this.countWeeksChart;
                break
            default:
                params.countDaysChart = this.countDaysChart;
        }
        if (this.getSku()) {
            return {...this.getFixFilterForInternalPage(), ...params}
        }
        return {...this.filter, ...params}
    }

    getFixFilterForInternalPage() {
        const allowedIntervals = ['startDate', 'endDate']
        const fixFilter = {}
        allowedIntervals.forEach((key) => {
            if (key in this.filter) {
                fixFilter[key] = this.filter[key]
            }
        })
        return fixFilter
    }

    handleConstraint(params) {
        this.countYearsChart = params.countDaysChart
        this.countMonthsChart = params.countMonthsChart
        this.countWeeksChart = params.countWeeksChart
        this.countDaysChart = params.countDaysChart
    }
}
